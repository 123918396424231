// import accountStateRef from "@/apps/accounts/modules/store";
import { Route } from "vue-router";
import { watch } from "@vue/composition-api";
import router from "@/router";

class NavItem {
  icon: string;
  label: string;
  routeName: string;
  params: Record<string, string>;
  groups: Array<string>;

  constructor(
    icon: string,
    label: string,
    routeName: string,
    params: Record<string, string> = {},
    groups: Array<string> = []
  ) {
    this.icon = icon;
    this.label = label;
    this.routeName = routeName;
    this.params = params;
    this.groups = groups;
  }
}

class NavBar {
  items: Array<NavItem>;
  urls: Array<string>; // navbar ini memayungi (aktif untuk) url mana saja

  constructor(items: Array<NavItem>, urls: Array<string>) {
    this.items = items;
    this.urls = urls;
  }
}

// Navbar Items
const homeItem = new NavItem("home", "Home", "home");
const donorHomeItem = new NavItem("home", "Home", "nasabah-home");
const pengelolaHomeItem = new NavItem("home", "Home", "pengelola-home");
// const donorListItem = new NavItem("user", "Nasabah", "donor-list");
const pinjamanItem = new NavItem("hand-holding-usd", "Pinjaman", "pinjaman-list");
const SetoranItem = new NavItem("wallet", "Simpanan", "setoran-list");
const NotesItem = new NavItem("file-alt", "SHU", "shu-list");
// const promoItem = new NavItem("gift", "Gift", "promo-list");
// const donorItem = new NavItem("user", "Akun", "donor-me");
// const pengelolaItem = new NavItem("user-md", "Akun", "pengelola-me");

// home Nasabah Navbar
const homeNavbar = new NavBar(
  Array.from([donorHomeItem, pinjamanItem, SetoranItem, NotesItem]),
  ["/home", "/anggota/residen", "/anggota/me"]
);

// home Pengelola Navbar
const homePengelolaNavbar = new NavBar(
  Array.from([pengelolaHomeItem, pinjamanItem, SetoranItem, NotesItem]),
  ["/home", "/anggota/me"]
);

abstract class NavBarState {
  navBarMap: Record<string, NavBar>;
  navItemMap: Record<string, NavBar> = {};
  current: NavBar;

  constructor(navBarMap: Record<string, NavBar>) {
    this.navBarMap = navBarMap;
    this.current = navBarMap["Home"];
  }

  getCurrentNavbar(currentRoute: Route): NavBar | null {
    const navbarArr = Object.values(this.navBarMap);
    const specificPaths = [
      "/anggota/me",
      "/programstudi/target",
      "/anggota/residen",
    ];
    let basePath = currentRoute.path;
    const pathArr = currentRoute.path.split("/");
    if (pathArr.length > 2) {
      // check two-level
      basePath = `/${pathArr[1]}/${pathArr[2]}`;
      if (!specificPaths.includes(basePath)) {
        basePath = `/${pathArr[1]}`;
      }
    } else {
      basePath = currentRoute.path.split("/")[1];
      basePath = `/${basePath}`;
    }
    for (const navBar of navbarArr) {
      if (navBar.urls.includes(basePath)) {
        return navBar;
      }
    }
    return null;
  }

  updateCurrent(currentRoute: Route): void {
    const navbar = this.getCurrentNavbar(currentRoute);
    if (navbar) {
      this.current = navbar;
    }

    if (currentRoute.name && currentRoute.name in this.navItemMap) {
      this.current = this.navItemMap[currentRoute.name];
    }
  }

  watchRoute(): void {
    watch(
      () => router.currentRoute,
      (currentRoute) => {
        this.updateCurrent(currentRoute);
      }
    );
  }
}

// Admin Bottom NavBar State
class AdminNavBarState extends NavBarState {
  constructor() {
    super({
      Home: homeNavbar,
    });

    this.navItemMap[homeItem.routeName] = this.navBarMap.Home;
    // this.navItemMap[programStudiItem.routeName] = this.navBarMap.ProgramStudi;
    // this.navItemMap[akademisItem.routeName] = this.navBarMap.Akademis;
    // this.navItemMap[targetItem.routeName] = this.navBarMap.Target;
  }
}

// Pengelola Bottom NavBar State
class PengelolaNavBarState extends NavBarState {
  navItemMap: Record<string, NavBar> = {};

  constructor() {
    super({
      Home: homePengelolaNavbar,
      // Kasus: kasusNavbar,
      // Akademis: akademisResidenNavbar,
    });

    this.navItemMap[homeItem.routeName] = this.navBarMap.Home;
    // this.navItemMap[kasusItem.routeName] = this.navBarMap.Kasus;
    // this.navItemMap[akademisItem.routeName] = this.navBarMap.Akademis;
  }
}

// Nasabah Bottom NavBar State
class NasabahNavBarState extends NavBarState {
  navItemMap: Record<string, NavBar> = {};

  constructor() {
    super({
      Home: homeNavbar,
    });

    this.navItemMap[homeItem.routeName] = this.navBarMap.Home;
    // this.navItemMap[kasusItem.routeName] = this.navBarMap.Kasus;
    // this.navItemMap[akademisItem.routeName] = this.navBarMap.Akademis;
  }
}

export {
  AdminNavBarState,
  PengelolaNavBarState,
  NasabahNavBarState,
  NavBarState,
  NavItem,
};

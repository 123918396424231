



















import { defineComponent, reactive } from "@vue/composition-api";
import accountStateRef from "@/apps/accounts/modules/store";
import {
  AdminNavBarState,
  PengelolaNavBarState,
  NasabahNavBarState,
  NavBarState,
} from "../models/bottomNavbar";

export default defineComponent({
  setup() {
    const navBarStateMap: Record<string, NavBarState> = {
      admin: new AdminNavBarState(),
      pengelola: new PengelolaNavBarState(),
      nasabah: new NasabahNavBarState(),
    };

    let navBarState = navBarStateMap.admin;
    if (accountStateRef.me.group) {
      navBarState = navBarStateMap[accountStateRef.me.group];
    }

    const navBarStateRef = reactive(navBarState);

    return {
      // Data
      navBarStateRef,
    };
  },
});
